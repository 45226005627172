import photo1 from "../src/assets/photos/news1.png";
import photo2 from "../src/assets/photos/news2.png";
import photo3 from "../src/assets/photos/news3.png";
import photo4 from "../src/assets/photos/news4.png";

export const NEWS = [
  {
    key: "1",
    photo: photo1,
    source: "baltosiospirstines.lt",
    url: "https://www.baltosiospirstines.lt/",
    title: "Penkiolikos eilučių naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-07-01",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "2",
    photo: photo2,
    source: "facebook",
    url: "https://www.facebook.com/",
    title: "Dviejų eilučių naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-07-28",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "3",
    photo: photo3,
    source: "delfi.lt",
    url: "https://www.delfi.lt",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-07-29",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "4",
    photo: photo4,
    source: "lrt.lt",
    url: "https://www.lrt.lt",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-07-30",
    tags: ["naujiena", "rinkimai"]
  },
  {
    key: "5",
    photo: photo1,
    source: "baltosiospirstines.lt",
    url: "https://www.baltosiospirstines.lt/",
    title: "Penkiolikos eilučių naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-07-31",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "6",
    photo: photo2,
    source: "facebook",
    url: "https://www.facebook.com/",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-02-01",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "7",
    photo: photo3,
    source: "delfi.lt",
    url: "https://www.delfi.lt",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2010-07-01",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "8",
    photo: photo4,
    source: "lrt.lt",
    url: "https://www.lrt.lt",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2014-07-01",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "9",
    photo: photo1,
    source: "baltosiospirstines.lt",
    url: "https://www.baltosiospirstines.lt/",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2015-03-15",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "10",
    photo: photo2,
    source: "facebook",
    url: "https://www.facebook.com/",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-09-01",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "11",
    photo: photo3,
    source: "delfi.lt",
    url: "https://www.delfi.lt",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-01-01",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "12",
    photo: photo4,
    source: "lrt.lt",
    url: "https://www.lrt.lt",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-07-13",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "13",
    photo: photo1,
    source: "baltosiospirstines.lt",
    url: "https://www.baltosiospirstines.lt/",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-07-11",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "14",
    photo: photo2,
    source: "facebook",
    url: "https://www.facebook.com/",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2019-12-01",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "15",
    photo: photo3,
    source: "delfi.lt",
    url: "https://www.delfi.lt",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2016-07-01",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "16",
    photo: photo4,
    source: "lrt.lt",
    url: "https://www.lrt.lt",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2018-07-01",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "17",
    photo: photo1,
    source: "baltosiospirstines.lt",
    url: "https://www.baltosiospirstines.lt/",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-06-01",
    tags: ["naujiena", "rinkimai"]
  },

  {
    key: "18",
    photo: photo2,
    source: "facebook",
    url: "https://www.facebook.com/",
    title: "Eilutės naujiena",
    subtitle:
      "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: "2020-07-06",
    tags: ["naujiena", "rinkimai"]
  }
];
