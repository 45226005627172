import React from "react";
import PropTypes from "prop-types";

import IconArrow from "../assets/icons/arrow.svg";
import LinkArrow from "../assets/icons/link-arrow.svg";
import LocalizedLink from "./localized-link";

import styles from "./news-items.module.scss";

const NewsItems = ({ newsItems, more, displayTags }) => (
  <ul className="bp-cards bp-4">
    {newsItems.map(({ key, photo, source, url, title, subtitle, date, tags }) => (
      <li key={key} className={`bp-card bp-interactive ${styles.newsItem}`}>
        <img src={photo} alt="" className={styles.photo} />
        <div className={styles.newsElementsContainer}>
          <b className={styles.source}>
            <LinkArrow className="bp-stroke bp-1" />
            {source}
          </b>
          <h2 className={styles.newsTitle} id={`news-heading-${key}`}>
            {title}
          </h2>
          <p className={styles.subtitle}>{subtitle}</p>
          <a
            href={url}
            className={styles.newsItemLink}
            target="_blank"
            rel="noreferrer noopener"
            aria-labelledby={`news-heading-${key}`}
          >
            <span className="bp-visually-hidden">{more}</span>
          </a>
          <b className={styles.more} aria-hidden="true">
            {more}
            <IconArrow className="bp-stroke" />
          </b>
          {displayTags ? (
            <div className={styles.tags}>
              {tags.map(tag => (
                <LocalizedLink className={styles.tag} key={tag} to={`/news?tag=${tag}`}>
                  #{tag}
                </LocalizedLink>
              ))}
            </div>
          ) : (
            <time className={styles.date} dt={date}>
              {date}
            </time>
          )}
        </div>
      </li>
    ))}
  </ul>
);

NewsItems.propTypes = {
  newsItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      photo: PropTypes.any.isRequired,
      source: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      tags: PropTypes.array.isRequired
    })
  ).isRequired,
  more: PropTypes.string.isRequired,
  displayTags: PropTypes.bool
};

NewsItems.defaultProps = {
  displayTags: false
};

export default NewsItems;
