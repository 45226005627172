import React, { useState } from "react";
import { graphql } from "gatsby";

import { NEWS } from "../../content/news";

import IconArrow from "../assets/icons/arrow.svg";

import { useTranslations } from "../hooks/translations";
import SEO from "../components/seo";
import NewsItems from "../components/news-items";

import styles from "./news.module.scss";

export const query = graphql`
  query NewsQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        news
      }
      ...LayoutTranslations
    }
  }
`;

const SORT_PARAM = {
  CHANNEL: "channel",
  DATE: "date"
};

const SORT_DIRECTION = {
  ASC: "ascending",
  DESC: "descending"
};

const SORT_CONFIG = {
  [SORT_PARAM.CHANNEL]: {
    defaultDirection: SORT_DIRECTION.ASC,
    translationKey: "sortByChannel",
    sortDescending: (a, b) => (a.source.toLowerCase() > b.source.toLowerCase() ? -1 : 1)
  },
  [SORT_PARAM.DATE]: {
    defaultDirection: SORT_DIRECTION.DESC,
    translationKey: "sortByDate",
    sortDescending: (a, b) => new Date(b.date) - new Date(a.date)
  }
};

const NewsPage = () => {
  const t = useTranslations("pages.news");

  const [currentSort, setSort] = useState(SORT_PARAM.DATE);
  const [currentDirection, setDirection] = useState(SORT_DIRECTION.DESC);

  const isDirectionDescending = currentDirection === SORT_DIRECTION.DESC;

  const newsDescending = NEWS.sort(SORT_CONFIG[currentSort].sortDescending);
  const sortedNewsItems = isDirectionDescending ? newsDescending : newsDescending.reverse();

  const updateSort = sortParam => {
    setSort(sortParam);

    if (sortParam === currentSort) {
      setDirection(isDirectionDescending ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC);
    } else {
      setDirection(SORT_CONFIG[sortParam].defaultDirection);
    }
  };

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />

      <section>
        <div className="bp-container">
          <h1>{t.heading}</h1>

          <div className={styles.sorting}>
            <span className={styles.sortBy} id="sort-by">
              {t.sortBy}
            </span>

            {Object.entries(SORT_CONFIG).map(([sortParam, { translationKey }]) => {
              const isButtonActive = currentSort === sortParam;

              return (
                <button
                  key={sortParam}
                  type="button"
                  className={`${styles.sortButton} ${isButtonActive ? "" : styles.inactive}`}
                  aria-labelledby="sort-by"
                  onClick={() => updateSort(sortParam)}
                >
                  <span className={styles.sortByLabel}>{t[translationKey]}</span>
                  <IconArrow
                    className={`bp-stroke ${isButtonActive ? "" : "bp-hidden"} ${
                      isDirectionDescending ? styles.down : styles.up
                    }`}
                  />
                </button>
              );
            })}
          </div>
          <NewsItems newsItems={sortedNewsItems} more={t.more} />
        </div>
      </section>
    </>
  );
};

export default NewsPage;
